import { Link } from 'gatsby'
import React from 'react'

import holgerUnterschrift from '../assets/images/holger.svg'

export default ({ author, title }) => (
  <section className="container my-5">
    <div
      className="d-flex justify-content-end w-100 p-3"
      style={{
        // top: 0,
        // right: 0,
        height: '600px',
        width: '100%',
        backgroundImage:
          'url(https://www.datocms-assets.com/11027/1558705421-flaschen-regal.png?auto=compress%2Cformat&fm=png&trim=auto&w=1400)',
        backgroundSize: 'cover',
        backgroundPositionY: '100%',
        boxShadow: '4px 4px 5px -2px rgba(163,163,163,1)',
      }}
    >
      <p className="col-12 col-sm-10 col-md-8 text-white">
        <span>
          Gin vom Xaver wird nur in kleinen Stückzahlen produziert. Wenn wir
          Zeit und Kraft haben, gibt es mal 200 Stück. Sehr schön, dass du eine
          Flasche bei dir hast. Die Etiketten sind auch von innen bedruckt.
          Schau genau!
        </span>
        <br />
        <img src={holgerUnterschrift} className="my-2" height="70px" />
        <br />
        <span>Xaver's Enkel</span>
      </p>
    </div>
  </section>
)
