import React from 'react'

export default () => (
  <section className="w-100 bg-white my-5">
    <img
      src="https://www.datocms-assets.com/11027/1554554513-illus-vogel-grau.png?auto=compress%2Cformat&fm=png&trim=auto&w=1300"
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        transform: 'translateX(-15%)',
        margin: 'auto',
        opacity: '0.5',
        // maxWidth: '100vw'
      }}
    />
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-4 col-md-6 justify-content-center">
          <div className="d-flex flex-column position-relative">
            <img
              src="https://www.datocms-assets.com/11027/1554553523-xaver-qualmend.png?auto=compress%2Cformat&fm=png&trim=auto&w=800"
              style={{ width: '100%', height: 'auto' }}
            />
            <img
              src="https://www.datocms-assets.com/11027/1554554519-illus-zigarre.png?auto=compress%2Cformat&fm=png&trim=auto&w=300"
              style={{
                position: 'absolute',
                bottom: 0,
                left: '-12%',
                margin: 'auto',
                opacity: '0.9',
              }}
            />
            <p className="image-subline">
              Xaver, qualmend. Ein Dauerzustand.
            </p>
          </div>
        </div>
        <div className="col-12 col-sm-8 col-md-6 justify-content-center position-relative">
          <div className="d-flex justify-content-center">
            <h1>Immer gute Ideen</h1>
          </div>
          <img
            src="https://www.datocms-assets.com/11027/1554554483-illus-hose.png?auto=compress%2Cformat&fm=png&trim=auto&w=140"
            style={{
              position: 'absolute',
              top: '40%',
              right: '-50px',
              margin: 'auto',
              opacity: '0.9',
            }}
          />
          <img
            src="https://www.datocms-assets.com/11027/1554554497-illus-nahzeug.png?auto=compress%2Cformat&fm=png&trim=auto&w=140"
            style={{
              position: 'absolute',
              top: '70%',
              left: '-20px',
              margin: 'auto',
              opacity: '0.8',
            }}
          />
          <p>
            Ich kann mich noch gut an den merkwürdigen Geruch frischer Luft
            erinnern, die endlich wieder auf dem Nachhauseweg zur Verfügung
            stand. Vorher erzeugten etwa 30 Kettenraucher am Tresen des Boxers
            eine Nikotinüberdosis. Den Besuch bei Flachert hatte ich zu
            verschweigen. Leider hing der Gestank in den Klamotten und machte
            jedes Leugnen vor meiner Oma zwecklos.
          </p>
          <p className="position-relative">
            In der riesigen Altbauwohnung betrieb mein Opa eine kleine
            Schneiderwerkstatt, wo er meinem Bruder und mir zwischendurch Hosen
            nähte. Untragbar waren die, sowohl optisch als auch technisch. Der
            Sakko- oder „Kratzestoff“ mit Karos und die nicht gefütterten
            Hosenbeine lösten bei uns keine Freude aus.{' '}
          </p>
          <p>
            Im Keller und in einem mit alten Möbeln vollgestellten Schuppen
            wurde gebrannt, gebraut und eingemacht. Während des Krieges in
            Frankreich hat er vermutlich auch das Rezept für einen Gin erhalten,
            das du nun als „Gin vom Xaver“ kosten kannst. Es ist kein super
            hippes Produkt mit Botanicals aus den Anden oder aus dem
            Marianengraben. Es ist ein ehrlicher, ungekünstelter Gin aus dem
            Ruhrgebiet, aus Wanne-Eickel, der Wiege der Ehrlichkeit.
          </p>
        </div>
      </div>
    </div>
  </section>
)
