import React from 'react'

export default () => (
  <section id="destillerie" className="w-100 bg-white my-5">
    <div
      className="container"
      style={{
        backgroundImage:
          'url(https://www.datocms-assets.com/11027/1554554419-illus-brennutensil-grau.png?auto=compress%2Cformat&fm=png&trim=auto&bri=8&w=1500)',
        backgroundSize: 'cover',
      }}
    >
      <div className="row justify-content-center">
        <div className="col-12 col-sm-12 justify-content-center position-relative">
          <h1 className="mb-4">
            Wie kommt der Gin
            <br />
            in die Flasche?
          </h1>
          <p>
            In der Chronik der Familie Callen wird bereits um 1749 von einem
            „Branntwynbrenner“ berichtet. Auf einem gepachteten Gelände in
            Wanne-Süd wurde seit Anfang des 19. Jahrhunderts Korn gebrannt. Das
            Grundstück wurde später für den Bau des Hauptbahnhofes Wanne-Eickel
            verkauft.
          </p>
          <p>
            Die Brennerei siedelte 1889 zu seinem heutigen Standort Am
            Heidkampsfeld in Wanne-Süd um. Der Brennmeister Wilhelm Conrad
            Callen und der Kaufmann Hermann Eicker gründeten am 29. August 1889
            die Kornbrennerei Eicker & Callen.
          </p>
          <p>
            Seit 2015 wird die Brennerei als Destillerie Eicker & Callen von
            Peter Meinken, dem Inhaber der Alten Drogerie Meinken, ebenfalls in
            Wanne-Eickel, geführt. Die Produkte von Eicker & Callen sind nur in
            wenigen ausgesuchten Geschäften, im Hausgeschäft der Brennerei und
            im Versandhandel zu erwerben.
          </p>
        </div>
      </div>
      <div className="row align-items-end">
        <div className="col-12 col-sm-4 d-flex flex-column position-relative">
          <img
            src="https://www.datocms-assets.com/11027/1554553148-abfullanlage.png?auto=compress%2Cformat&fm=png&trim=auto&w=800"
            style={{ width: '100%', height: 'auto' }}
          />
          <p className="image-subline">
            Hier die „automatische“
            <br />
            Abfüllanlage.
          </p>
          <div className="mt-n5 mb-1">
            <img
              src="https://www.datocms-assets.com/11027/1554553142-7.png?auto=compress%2Cformat&fm=png&trim=auto&w=80"
              style={{
                marginLeft: '60%',
              }}
            />
          </div>
          <img
            src="https://www.datocms-assets.com/11027/1554553450-temperaturanzeige.png?auto=compress%2Cformat&fm=png&trim=auto&w=800"
            style={{ width: '100%', height: 'auto' }}
          />
          <img
            src="https://www.datocms-assets.com/11027/1554553137-5.png?auto=compress%2Cformat&fm=png&trim=auto&w=100"
            style={{
              position: 'absolute',
              top: '56%',
              left: '-16%',
              margin: 'auto',
            }}
          />
          <p className="image-subline">
            Eicker & Callen. Leidenschaft,
            <br /> Handwerk und über 250 Jahre Brenntradition. Temperaturanzeige
            im Alkoholometer.
          </p>
        </div>
        <div className="col-12 col-sm-5 d-flex flex-column">
          <img
            src="https://www.datocms-assets.com/11027/1557911008-herr-callen.png?auto=compress%2Cformat&fm=png&trim=auto&w=800"
            style={{ width: '100%', height: 'auto' }}
          />
          <p className="image-subline">
            Der Gin vom Xaver ist unter der Leitung des letzten Brennmeisters
            von Eicker & Callen, Herrn Thomas Callen, entstanden. Dafür hat er
            freundlicherweise seinen Ruhestand kurzfristig unterbrochen.
          </p>
        </div>
        <div className="col-12 col-sm-3 d-flex flex-column align-items-center align-self-center">
          <img
            className="d-none d-sm-flex"
            style={{ width: '200%', height: 'auto' }}
            src="https://www.datocms-assets.com/11027/1554553172-anhanger.png?auto=compress%2Cformat&fm=png&trim=auto&w=700"
          />
          <img
            className="mt-5"
            style={{ width: '100%', height: 'auto', zIndex: '1000000' }}
            src="https://www.datocms-assets.com/11027/1554553290-flasche.png?auto=compress%2Cformat&fm=png&trim=auto&w=600"
          />
        </div>
      </div>
    </div>
  </section>
)
