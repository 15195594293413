import { useState, useCallback } from 'react'

const useToggle = initialValue => {
  const [value, setValue] = useState(initialValue)
  // const toggler = useCallback(() => setValue(value => !value))
  const toggler = useCallback(
    nextValue => {
      if (typeof nextValue !== 'undefined') {
        setValue(!!nextValue)
        return
      }

      setValue(value => !value)
    },
    [setValue]
  )
  return [value, toggler]
}

export default useToggle
