import React from 'react'
import schnurband from '../assets/images/schnurband.png'

export default () => (
  <section id="herstellung" className="container-fluid bg-white my-5">
    <img
      src="https://www.datocms-assets.com/11027/1554554477-illus-grafik-rosette.png?auto=compress%2Cformat&fm=png&w=650"
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: '-26%',
        // transform: 'translateX(56%)',
        margin: 'auto',
        opacity: '0.8',
      }}
    />
    <div className="container">
      <div className="d-flex w-100 justify-content-center my-3">
        <h1>Handgemacht in Wanne-Eickel</h1>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-sm-6 justify-content-center">
          <img
            src="https://www.datocms-assets.com/11027/1558705106-befullunggelb.png?auto=compress%2Cformat&fm=jpg&trim=auto&w=800"
            style={{ width: '100%', height: 'auto' }}
          />
          <p className="image-subline">
            Jede Flasche wird von Hand befüllt. Die ungewöhnliche Form lässt nur
            eine Einzelabfüllung zu.
          </p>
        </div>
        <div className="col-12 col-sm-6 justify-content-center">
          <img
            src="https://www.datocms-assets.com/11027/1558705180-leimunggelb.png?auto=compress%2Cformat&fm=jpg&trim=auto&w=800"
            style={{ width: '100%', height: 'auto' }}
          />
          <p className="image-subline">Die Hightech-Leimmaschine im Einsatz.</p>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-sm-6 justify-content-center">
          <img
            src="https://www.datocms-assets.com/11027/1558705217-nummerierunggelb.png?auto=compress%2Cformat&fm=jpg&trim=auto&w=800"
            style={{ width: '100%', height: 'auto' }}
          />
          <p className="image-subline">
            Ruhiges Händchen erforderlich. Jede Flasche wird nummeriert und mit
            einer Losnummer versehen.{' '}
          </p>
        </div>
        <div className="col-12 col-sm-6 justify-content-center">
          <img
            src="https://www.datocms-assets.com/11027/1558705140-klebunggelb.png?auto=compress%2Cformat&fm=jpg&trim=auto&w=800"
            style={{ width: '100%', height: 'auto' }}
          />
          <p className="image-subline">
            Wer die Flasche leert, darf den Vogel fliegen lassen. Am besten mit
            dem Lederarmband um den Hals getragen.
          </p>
        </div>
      </div>
      <div className="row justify-content-center align-items-end my-5 position-relative">
        <img
          src={schnurband}
          className="w-100 h-auto"
            style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: '0 auto',
          }}
        />
        <div className="col-12 mb-4 col-sm-5 justify-content-center">
          <p className="h2 mb-5 font-handwritten">
            Jede Flasche Gin vom Xaver kommt als Set mit Broschüre und zwei
            Bierdeckeln in der bedruckten Papiertüte.
          </p>
        </div>
        <div className="col-12 col-sm-7 justify-content-center">
          <img
            src="https://www.datocms-assets.com/11027/1557911025-flasche-im-paket.png?auto=compress%2Cformat&fm=png&trim=auto&w=800"
            style={{ width: '100%', height: 'auto' }}
          />
        </div>
      </div>
    </div>
  </section>
)
