import { Link } from 'gatsby'
import React from 'react'

import DerKu from '../assets/images/Ku Logo.png'

const Kontakt = ({ author, title }) => (
  <section id="kontakt" className="container-fluid my-5">
    <img
      src="https://www.datocms-assets.com/11027/1554554471-illus-grafik-rosette-grau.png?auto=compress%2Cformat&fm=png&w=650"
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        transform: 'translateX(-56%)',
        margin: 'auto',
        opacity: '0.6',
      }}
    />
    <div className="row justify-content-center mt-5 mb-4">
      <h1>Kontakt</h1>
    </div>
    <div className="row justify-content-center">
      <div className="col-12 col-sm-3 d-flex justify-content-start justify-content-sm-end">
        <div>
          <img
            src={DerKu}
            height="100px"
            alt="Der Ku"
            style={{
              width: '7rem',
              height: 'auto',
              marginTop: '-15px',
              marginBottom: '15px',
            }}
          />
        </div>
      </div>
      <div className="col-12 col-sm-8 col-md-5">
        <p
          style={{
            fontFamily: 'TrendRoughSansFive',
            fontSize: '0.9em',
            lineHeight: '1.2',
          }}
        >
          <span>Veilchenweg 16</span>
          <br />
          <span>44651 Herne</span>
          <br />
          <span>Destilliert in Wanne-Eickel</span>
          <br />
          <span>BEI EICKER UND Callen</span>
          <br />
        </p>
      </div>
    </div>
  </section>
)

export default Kontakt
