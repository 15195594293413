import React from 'react'

export default () => (
  <section className="container my-5">
    <img
      src="https://www.datocms-assets.com/11027/1554554466-illus-flaschen.png?auto=compress%2Cformat&fm=png&trim=auto&w=250"
      style={{
        position: 'absolute',
        top: '-13%',
        right: '-5%',
        opacity: '0.8',
      }}
    />
    <div className="row justify-content-center my-3">
      <h1>Das war der Xaver</h1>
    </div>
    <div className="row justify-content-center">
      <div className="col-12 justify-content-center">
        <p>
          Xaver brachte Lora, dem Papagei, das Sprechen bei, diversen
          Kanarienvögeln und Rauhaardackeln nicht, sprach aber trotzdem mit
          allen. Ja, die Kanarienvögel waren natürlich „arme Schweine“, weil die
          nämlich in den vielen Herner Zechen die Gaskonzentration unter Tage
          anzeigen sollten. Piepen hieß: Luft o. k., auf dem Boden liegen
          bedeutete, sie war nicht mehr o. k. „Die sollten die Vögel fliegen
          lassen“ war sein eindringlicher Appell.
        </p>
        <br />
      </div>
    </div>
    <div className="row justify-content-center">
      <div className="col-12 col-sm-3 justify-content-center">
        <div className="d-flex flex-column">
          <img
            src="https://www.datocms-assets.com/11027/1554553504-xaver-klein.png?auto=compress%2Cformat&fm=png&trim=auto&w=800"
            style={{ width: '100%', height: 'auto' }}
          />
          <p className="image-subline">Xaver, ein Mann des guten Geschmacks.</p>
          <img
            src="https://www.datocms-assets.com/11027/1554553533-xaver-trude.png?auto=compress%2Cformat&fm=png&trim=auto&w=800"
            style={{ width: '100%', height: 'auto' }}
          />
          <p className="image-subline">
            Xaver und seine Trude, auf dem Tisch vermutlich Produkte aus eigener
            Herstellung.
          </p>
        </div>
      </div>
      <div className="col-12 col-sm-9 justify-content-center">
        <p>
          Er hatte jedoch auch tiefgründigere Meinungen, nämlich zu Politik und
          Nationalsozialismus. Den Eintritt in die Partei verweigerte er
          konsequent. Trotzdem musste er während des zweiten Weltkriegs mit der
          Wehrmacht in Frankreich einmarschieren. Dort war er einige Jahre im
          Lazarett eingesetzt. „Ich habe nie ein Gewehr getragen“ war seine
          Formel, die klarmachte, dass er sich aus dem Krieg so gut es ging
          herausgehalten hatte.
        </p>
        <div className="mt-n5 mb-n4">
          <img
            src="https://www.datocms-assets.com/11027/1554554468-illus-gewehr.png?auto=compress%2Cformat&fm=png&trim=auto&w=240"
            className="mr-5 mr-sm-0 mr-md-5"
          />
          <img
            className="ml-5 ml-sm-0 ml-md-5"
            src="https://www.datocms-assets.com/11027/1554554505-illus-vogel-fliegend.png?auto=compress%2Cformat&fm=png&trim=auto&w=100"
          />
        </div>
        <img
          src="https://www.datocms-assets.com/11027/1554554497-illus-nahzeug.png?auto=compress%2Cformat&fm=png&trim=auto&w=140"
          style={{
            position: 'absolute',
            top: '70%',
            left: '-20px',
            margin: 'auto',
            opacity: '0.8',
          }}
        />
        <p>
          Nach dem Krieg erlebte man viele Jahre flammende Plädoyers gegen
          Hitler „diese alte Sau“ und das Dritte Reich insgesamt, das nämlich
          „ein Scheiß war“. Auch Werner Höfer, den Moderator der politische
          Talkrunde „Frühschoppen“, attackierte er genau jeden Sonntag lautstark
          als Nazi, während er alleine im Bademantel paffend vor dem Fernseher
          saß. Insbesondere Statements von Franz Josef Strauß gaben regelmäßig
          Anlass zu laustarken Verwünschungen. In seiner Aufregung versengte er
          dabei ständig Tisch und Decken mit den glühenden „Handelsgold mit
          Mundstück“. Ein guter Typ also.
        </p>
        <br />
        <p>
          Er hat mich sonntags gerne mitgenommen auf eine Runde mit der „Biene“,
          dem Rauhaardackel. Die war nie lang, weil sie meistens recht schnell
          bei „Flachert“ endete, einer Ruhrgebietskneipe in der Wanner
          Innenstadt, die von einem Boxer betrieben wurde.
        </p>
      </div>
    </div>
  </section>
)
