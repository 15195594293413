import React, { Component } from 'react'
import axios from 'axios'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Recaptcha from 'react-recaptcha'

const API_PATH = 'https://gvxmail.vercel.app/api/sendMail.php'
// const MAIL_RECIPIENT = 'schon.florian@gmail.com'
const MAIL_RECIPIENT = 'info@ginvomxaver.de'
const RECAPTCHA_SITE_KEY = '6LfTs6MUAAAAAN3FxQlrMSEJai-IS67jjajeaZBM'

const pricePerBottle = 32.5
const getFullPrice = bottles =>
  bottles < 6 ? bottles * pricePerBottle + 5 : bottles * pricePerBottle + 8

export default class ContactForm extends Component {
  state = {
    // isCaptchaValid: false,
    isErrorShown: false,
    isFormValid: false,
  }

  // // Show message in console when reCaptcha plugin is loaded
  // onCaptchaLoad = () => {
  //   console.log('Captcha loaded')
  // }

  // // Update state after reCaptcha validates visitor
  // onCaptchaVerify = response => {
  //   console.log(response)
  //   this.setState({
  //     isCaptchaValid: true,
  //   })
  // }

  render() {
    return (
      <Formik
        initialValues={{ name: '', email: '', message: '' }}
        validate={values => {
          let errors = {}
          if (!values.firstname) errors.firstname = 'Feld benötigt'
          if (!values.name) errors.name = 'Feld benötigt'
          if (!values.address) errors.address = 'Feld benötigt'
          if (!values.email) errors.email = 'Feld benötigt'
          if (!values.bottles)
            errors.bottles = 'Bitte Zahl zwischen 1 und 10 eintragen'
          if (!values.proof) errors.proof = 'Antwort benötigt'
          if (values.proof !== 12) errors.proof = 'Falsche Antwort'
          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Fehlende / Ungültige E-Mail-Adresse'
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            axios({
              method: 'post',
              url: `${API_PATH}`,
              data: JSON.stringify({
                to: MAIL_RECIPIENT,
                ...values,
                shipping: values.bottles > 5 ? '8 €' : '5 €',
                total: getFullPrice(values.bottles),
              }),
            })
              .then(result => {
                if (result.data.sent) {
                  console.log({
                    mailSent: result.data.sent,
                  })
                  this.setState({
                    isFormSubmitted: true,
                  })
                } else {
                  this.setState({
                    isErrorShown: true,
                  })
                }
              })
              .catch(error => {
                console.log({ error: error.message })
                this.setState({
                  isErrorShown: true,
                })
              })
              .finally(setSubmitting(false))
          }, 400)
        }}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <div className="row">
              <div className="form-group col-3 col-sm-2">
                <label className="control-label my-label" htmlFor="firstname">
                  Vorname
                </label>
              </div>
              <div className="form-group col-9 col-sm-4">
                <Field
                  type="text"
                  name="firstname"
                  className="form-control my-input input-md"
                />
                <ErrorMessage
                  name="firstname"
                  component="div"
                  className="custom-form-error"
                />
              </div>
              <div className="form-group col-3 col-sm-1">
                <label className="control-label my-label" htmlFor="name">
                  Name
                </label>
              </div>
              <div className="form-group col-9 col-sm-5">
                <Field
                  type="text"
                  name="name"
                  className="form-control my-input input-md"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="custom-form-error"
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-3 col-sm-2">
                <label className="control-label my-label" htmlFor="address">
                  Adresse
                </label>
              </div>
              <div className="form-group col-9 col-sm-10">
                <Field
                  type="text"
                  name="address"
                  className="form-control my-input input-md"
                />
                <ErrorMessage
                  name="address"
                  component="div"
                  className="custom-form-error"
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-3 col-sm-2">
                <label className="control-label my-label" htmlFor="email">
                  E-Mail
                </label>
              </div>
              <div className="form-group col-9 col-sm-10">
                <Field
                  type="email"
                  name="email"
                  className="form-control my-input input-md"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="custom-form-error"
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-2">
                <Field
                  type="number"
                  name="bottles"
                  className="form-control my-input-number input-md"
                />
                <ErrorMessage
                  name="bottles"
                  component="div"
                  className="custom-form-error"
                />
              </div>
              <div className="form-group col-10">
                <label className="control-label my-label" htmlFor="bottles">
                  Anzahl Flaschen (0,5 ltr)
                  <br />
                  zum Preis von 32,50 Euro pro flasche (incl. 19% mwst.)
                </label>
              </div>
            </div>
            {values.bottles && (
              <>
                <div className="row mb-n3">
                  <div className="col-9 col-sm-4">
                    <p className="my-label">zzgl. Versand</p>
                  </div>
                  <div className="col-3 col-sm-2">
                    <p className="my-label text-right">
                      {values.bottles > 5 ? '8 €' : '5 €'}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-9 col-sm-4">
                    <p className="my-label">Gesamtpreis der Bestellung</p>
                  </div>
                  <div className="col-3 col-sm-2">
                    <p className="my-label text-right">
                      {getFullPrice(values.bottles).toLocaleString('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                      })}
                    </p>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="form-group col-6 col-sm-4">
                    <label className="control-label my-label" htmlFor="proof">
                      Sicherheitsfrage:
                      <br />
                      Was ist 2 + 1O?
                    </label>
                  </div>
                  <div className="form-group col-2">
                    <Field
                      type="number"
                      name="proof"
                      className="form-control my-input-number input-md"
                    />
                    <ErrorMessage
                      name="proof"
                      component="div"
                      className="custom-form-error"
                    />
                  </div>
                </div>
              </>
            )}
            <div className="row justify-content-center">
              <div className="form-group col-12 col-sm-8 justify-content-center">
                {/* <Recaptcha
                  onloadCallback={this.onCaptchaLoad}
                  sitekey={`${RECAPTCHA_SITE_KEY}`}
                  // render="explicit"
                  verifyCallback={this.onCaptchaVerify}
                /> */}
                <div className="d-flex w-100 justify-content-center">
                  <button
                    type="submit"
                    className="btn "
                    // disabled={isSubmitting || !this.state.isCaptchaValid}
                    disabled={
                      isSubmitting || !values.proof || values.proof.errors
                    }
                  >
                    ABSENDEN
                  </button>
                </div>
                {this.state.isFormSubmitted && (
                  <p className="custom-form-success text-center">
                    Ihre Nachricht wurde erfolgreich gesendet. Vielen Dank!
                  </p>
                )}

                {this.state.isErrorShown && (
                  <p className="custom-form-error text-center">
                    Die Nachricht konnte nicht gesendet werden. Bitte versuchen
                    Sie es noch einmal zu einem späteren Zeitpunkt oder
                    informieren Sie den Administrator der Webseite.
                  </p>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    )
  }
}
