import React from 'react'
// import emergence from 'emergence.js'
import CookieConsent from 'react-cookie-consent'

import Navi from './Navi.js'
// import Footer from './Footer.js'
import { siteMetadata } from '../../gatsby-config'

import 'modern-normalize/modern-normalize.css'
import '../scss/gatstrap.scss'
import 'animate.css/animate.css'
// import 'font-awesome/css/font-awesome.css'

export default class Layout extends React.Component {
  // componentDidMount() {
  //   emergence.init()
  // }

  // componentDidUpdate() {
  //   emergence.init()
  // }

  render() {
    const { children } = this.props

    return (
      <div>
        <Navi title={siteMetadata.title} {...this.props} />
        {children}
        {/* <Footer title={siteMetadata.title} author={siteMetadata.author} /> */}
        <CookieConsent
          // disableStyles={true}
          style={{ zIndex: '1000000' }}
          location="bottom"
          buttonText="OK."
          buttonClasses="btn btn-primary"
          containerClasses="col-lg-12 bg-dark justify-content-center"
          contentClasses="text-white"
        >
          Diese Website setzt einen Cookie, der für den reibungslosen Betrieb
          der Seite erforderlich ist.{' '}
          {/* <span style={{ fontSize: "10px" }}>
            This bit of text is smaller :O
          </span> */}
        </CookieConsent>
      </div>
    )
  }
}
