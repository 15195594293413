import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import Parser from 'html-react-parser'

// import Meta from '../components/Meta.js'
import Layout from '../components/Layout.js'
import SectionHalf from '../components/SectionHalf.js'
import SectionFull from '../components/SectionFull.js'

import SectionGinInFlasche from '../components/SectionGinInFlasche'
import SectionHandgemacht from '../components/SectionHandgemacht'
import SectionWarXaver1 from '../components/SectionWarXaver1'
import SectionWarXaver2 from '../components/SectionWarXaver2'
import SectionGuteIdeen from '../components/SectionGuteIdeen'
import SectionRegal from '../components/SectionRegal'
import Form from '../components/Form'
import Kontakt from '../components/Kontakt'
import Impressum from '../components/Impressum'

const HomePage = ({ data, location }) => {
  const botanicals = data.modelBotanicals.edges.map(e => (
    <img height="90px" key={e.node.image.id} src={e.node.image.url} />
  ))

  // const getSectionData = headline =>
  //   data.modelTextSektionen.edges.find(
  //     x => x.node.headline.slice(0, 7) === headline
  //   )
  // console.log(sec);
  // const getestetNode = getSectionData('Geteste').node

  return (
    <Layout location={location}>
      {/* <Meta site={get(data, 'site.meta')} /> */}
      <section id="gin" className="w-100 ">
        <img
          src="https://www.datocms-assets.com/11027/1554554513-illus-vogel-grau.png?auto=compress%2Cformat&fm=png&trim=auto&w=1300"
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            transform: 'translateX(-16%)',
            margin: 'auto',
            opacity: '0.5',
          }}
        />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-10 col-md-8 justify-content-center">
              <img
                style={{ width: '100%', height: 'auto' }}
                src="https://www.datocms-assets.com/11027/1558705300-flasche-einzeln-frontal.png?auto=compress&fit=crop&trim=auto&w=1000"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid bg-gvx-yellow py-4 mb-3">
        <div className="row justify-content-center">
          <h1>Xaver's Botanicals</h1>
        </div>
        <div className="row justify-content-center">
          {botanicals.slice(0, 3)}
        </div>
        <div className="row justify-content-center">
          {botanicals.slice(3, 7)}
        </div>
        <div className="row justify-content-center">
          {botanicals.slice(7, 11)}
        </div>
      </section>
      {/* {data.modelSections.edges.map(e => (
        <section className="container">
          <div className="row justify-content-center">
            <h1>{e.node.headline}</h1>
          </div>
          {e.node.inhalt.map(block => {
            if (block.model.apiKey === 'block_text')
              return (
                <div className="row justify-content-center">
                  <p>{Parser(block.text)}</p>
                </div>
              )
            if (block.model.apiKey === 'block_img_subline')
              return (
                <div className="d-flex flex-column w-50">
                  <Img fluid={block.image.fluid} />
                  <p className="image-subline">{block.subline}</p>
                </div>
              )
          })}
        </section>
      ))} */}
      <SectionGinInFlasche />
      <SectionHandgemacht />
      <SectionWarXaver1 />
      <SectionWarXaver2 />
      <SectionGuteIdeen />
      <SectionRegal />
      <section id="bestellen" className="container my-5">
        <div className="row justify-content-center my-5">
          <h1>GIN VOM XAVER BESTELLEN</h1>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 justify-content-center">
            <Form />
          </div>
        </div>
      </section>
      <Kontakt />
      <Impressum />
    </Layout>
  )
}

export default HomePage

export const query = graphql`
  query AllDataQuery {
    modelSections: allDatoCmsSection {
      edges {
        node {
          id
          headline
          inhalt {
            ... on DatoCmsBlockText {
              model {
                apiKey
              }
              id
              text
            }
            ... on DatoCmsBlockImgSubline {
              model {
                apiKey
              }
              id
              image {
                url
                fluid(
                  maxWidth: 1500
                  imgixParams: { fm: "jpg", auto: "compress" }
                ) {
                  ...GatsbyDatoCmsFluid
                }
              }
              subline
            }
          }
        }
      }
    }
    modelBotanicals: allDatoCmsBotanical {
      edges {
        node {
          id
          image {
            url
          }
        }
      }
    }
  }
`
