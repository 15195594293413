module.exports = {
  siteMetadata: {
    title: 'Gin vom Xaver',
    description:
      'Ein neuer ehrlicher Gin aus Wanne-Eickel in feiner Aufmachung. Von Meisterhand destilliert, nach dem alten Rezept vom Xaver, meinem Opa. Lasst den Vogel fliegen...',
    siteUrl: 'https://ginvomxaver.de',
    author: '© Der Ku UG',
  },
  pathPrefix: '/',
  plugins: [
    {
      resolve: `gatsby-source-datocms`,
      options: {
        apiToken: `1970c491bfcaeea573ac5445ef37cd`,
        preview: false,
        disableLiveReload: false,
      },
    },
    // {
    //   resolve: 'gatsby-source-filesystem',
    //   options: {
    //     path: `${__dirname}/content/posts/`,
    //     name: 'posts',
    //   },
    // },
    // {
    //   resolve: 'gatsby-source-filesystem',
    //   options: {
    //     path: `${__dirname}/content/images/`,
    //     name: 'images',
    //   },
    // },
    {
      resolve: `gatsby-plugin-nprogress`,
      options: {
        color: `#dd0615`,
        showSpinner: true,
      },
    },
    {
      resolve: 'gatsby-transformer-remark',
      options: {
        plugins: [
          {
            resolve: 'gatsby-remark-images',
            options: {
              maxWidth: 750,
              linkImagesToOriginal: false,
              wrapperStyle: 'margin-bottom: 1.0725rem;',
            },
          },
          {
            resolve: 'gatsby-remark-responsive-iframe',
            options: {
              wrapperStyle: 'margin-bottom: 1.0725rem',
            },
          },
          'gatsby-remark-copy-linked-files',
        ],
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: 'Gin vom Xaver',
        short_name: 'Gin v. Xaver',
        description:
          'Ein neuer ehrlicher Gin aus Wanne-Eickel in feiner Aufmachung. Von Meisterhand destilliert, nach dem alten Rezept vom Xaver, meinem Opa. Lasst den Vogel fliegen...',
        homepage_url: 'https://ginvomxaver.de',
        start_url: '/',
        background_color: '#fff',
        theme_color: '#ffdd00',
        display: 'standalone',
        icons: [
          {
            src: '/img/android-chrome-192x192.png',
            sizes: '192x192',
            type: 'image/png',
          },
          {
            src: '/img/android-chrome-512x512.png',
            sizes: '512x512',
            type: 'image/png',
          },
        ],
      },
    },
    {
      resolve: 'gatsby-plugin-netlify',
      options: {
        mergeSecurityHeaders: true,
        mergeLinkHeaders: true,
        mergeCachingHeaders: true,
      },
    },
    'gatsby-plugin-catch-links',
    'gatsby-plugin-offline',
    'gatsby-plugin-react-helmet',
    'gatsby-plugin-sass',
    'gatsby-plugin-sharp',
    'gatsby-plugin-sitemap',
    'gatsby-transformer-sharp',
  ],
}
