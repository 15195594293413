import React from 'react'

export default () => (
  <section id="xaver" className="w-100 bg-white my-5">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-md-5 justify-content-center">
          <div className="d-flex flex-column position-relative">
            <img
              src="https://www.datocms-assets.com/11027/1554553470-xaver-garten.png?auto=compress%2Cformat&fm=png&trim=auto&w=800"
              style={{ width: '100%', height: 'auto' }}
            />
            <img
              src="https://www.datocms-assets.com/11027/1554553126-1.png?auto=compress%2Cformat&fm=png&trim=auto&w=120"
              style={{
                position: 'absolute',
                top: '-15%',
                left: '-17%',
                margin: 'auto',
              }}
            />
            <img
              src="https://www.datocms-assets.com/11027/1554553130-2.png?auto=compress%2Cformat&fm=png&trim=auto&w=120"
              style={{
                position: 'absolute',
                bottom: '12%',
                left: '-14%',
                margin: 'auto',
              }}
            />
            <img
              src="https://www.datocms-assets.com/11027/1554553135-4.png?auto=compress%2Cformat&fm=png&trim=auto&w=100"
              style={{
                position: 'absolute',
                bottom: '32%',
                right: '-12%',
                margin: 'auto',
              }}
            />
            <img
              src="https://www.datocms-assets.com/11027/1554553133-3.png?auto=compress%2Cformat&fm=png&trim=auto&w=100"
              style={{
                position: 'absolute',
                bottom: '10%',
                right: '-11%',
                margin: 'auto',
              }}
            />
            <p className="image-subline">
              Xaver im riesigen Garten der Familie. Botanicals hießen bei ihm
              noch Gemüse, Kräuter und Früchte.
            </p>
          </div>
        </div>
        <div className="col-12 col-md-7 justify-content-center position-relative">
          <div className="d-flex justify-content-center mb-2">
            <h1>Wer war der Xaver?</h1>
          </div>
          <p>
            Mein Opa Xaver ist 1910 geboren worden und verstarb 1983 in
            Wanne-Eickel. Dort lebte er mit seiner Familie sein gesamtes Leben
            lang. Er hat sich mit vielen Dingen beschäftigt. Er war Schneider,
            betrieb eine Maßschneiderei und ein Fachgeschäft für Hosen. In
            seiner Freizeit qualmte er eigentlich dauernd stinkende Cigarellos
            und arbeitete im Garten. Dieser war für heutige Verhältnisse eher
            eine Art Farm. Gemüse und Früchte wurden dort in schier endlosen
            Massen geerntet. Diese mussten komplett verarbeitet werden.
          </p>
          <p>
            Unter anderem wurden die Früchte des Gartens auch zu alkoholischen
            Spezialitäten verarbeitet. Wodka, Wein, Aufgesetzter, Obstbrand,
            Bier und eben Gin wurden produziert. Alles wurde in unterschiedliche
            Flaschen gefüllt, mit handgeschriebenen „Etiketten“ aus Pflastern
            beklebt und im Keller eingelagert.
          </p>
        </div>
      </div>
    </div>
  </section>
)
