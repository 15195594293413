import React, { useState } from 'react'
import useToggle from './useToggle'

import EttiketRueck from '../assets/images/Illus-Etikett-rück.png'

const mailPart1 = 'info'
const mailPart2 = 'ginvomxaver.de'

const Impressum = () => {
  const [showMail, setShowMail] = useState(false)
  const [datenschutzExpanded, toggle] = useToggle(false)

  return (
    <section id="impressum" className="container my-5">
      <div className="row justify-content-center mt-5 mb-4">
        <h1>Impressum</h1>
      </div>
      <div className="row justify-content-center mb-3">
        <div className="col-12 col-sm-8 text-center">
          <p>
            Verantwortlicher Anbieter dieses Internetauftritts im
            datenschutzrechtlichen Sinne ist:
            <br />
            Der Ku UG / Holger Wennrich
          </p>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-sm-6 col-md-5 text-sm-right">
          <p>
            Telefon 02325 797972
            <br />
            Mobil 0178 7979720
            <br />
            <br />
            {showMail ? (
              <a href={`mailto:${mailPart1}@${mailPart2}`}>
                info@ginvomxaver.de
              </a>
            ) : (
              <span className="c-pointer" onClick={() => setShowMail(true)}>
                Mailadresse anzeigen
              </span>
            )}
            <br />
            www.ginvomxaver.de
          </p>
        </div>
        <div className="col-12 col-sm-6 col-md-5">
          <p>
            StNr.: 325/5819/0473
            <br />
            USt.-IdNr.: DE279 888 505
            <br />
            HRB: 13514
            <br />
            Der Ku (UG) – Haftungsbeschränkt
            <br />
            Geschäftsführer: Holger Wennrich
          </p>
        </div>
      </div>
      <div className="row mt-4 justify-content-center">
        <div className="col-12 d-flex justify-content-center">
          <h6 onClick={() => toggle()}>
            >>> Datenschutzbestimmungen einblenden
          </h6>
        </div>
      </div>
      {datenschutzExpanded && (
        <div className="row mt-3 justify-content-center">
          <div className="col-12 ">
            <div className="d-flex d-md-none">
              <embed
                src="https://activate.reclay.de/ShowLogo200.html?clientId=112804"
                width={240}
                height={240}
              />
            </div>
            <div
              className="d-none d-md-flex"
              style={{ position: 'absolute', right: '1%' }}
            >
              <embed
                src="https://activate.reclay.de/ShowLogo100.html?clientId=112804"
                width={120}
                height={120}
              />
            </div>
            <p style={{ width: '80%' }}>
              Alle hier gezeigten Fotos, Logos und Texte stammen von Der Ku UG
              bzw. liegen dem Unternehmen die entsprechenden
              Verwendungsfreigaben der Urheber vor.
            </p>
            <br />
            <p>Haftung für Inhalte</p>
            <p>
              Die auf den Web-Seiten abrufbaren Beiträge dienen nur der
              allgemeinen Information und nicht der Beratung in konkreten
              Fällen. Wir sind bemüht, für die Richtigkeit und Aktualität aller
              auf der Website enthaltenen Informationen und Daten gemäß § 7
              Abs.1 TMG zu sorgen. Für die Korrektheit, die Vollständigkeit, die
              Aktualität oder Qualität der bereitgestellten Informationen und
              Daten wird jedoch keine Gewähr nach § 8 bis 10 TMG übernommen. Die
              Haftung für den Inhalt der abrufbaren Informationen wird
              ausgeschlossen, soweit es sich nicht um vorsätzliche oder grob
              fahrlässige Falschinformation handelt. Verpflichtungen zur
              Entfernung oder Sperrung der Nutzung von Informationen nach den
              allgemeinen Gesetzen bleiben hiervon unberührt. Eine
              diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
              Kenntnis einer konkreten Rechtsverletzung möglich. Bei
              Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
              diese Inhalte umgehend entfernen.
            </p>
            <br />
            <p>Haftung für Links</p>
            <p>
              Wir sind für den Inhalt von Webseiten, die über einen Hyperlink
              erreicht werden, nicht verantwortlich. Für den Inhalt der
              verlinkten Seiten sind ausschließlich deren Betreiber
              verantwortlich. Wir machen uns die Inhalte dieser Internetseiten
              ausdrücklich nicht zu eigen und können deshalb für die inhaltliche
              Korrektheit, Vollständigkeit und Verfügbarkeit keine Gewähr
              leisten. Wir haben bei der erstmaligen Verknüpfung zwar den
              fremden Inhalt daraufhin überprüft, ob durch ihn eine mögliche
              zivilrechtliche oder strafrechtliche Verantwortlichkeit ausgelöst
              wird. Wir sind aber nicht dazu verpflichtet, die Inhalte, auf die
              wir unserem Angebot verweisen, ständig auf Veränderungen zu
              überprüfen, die eine Verantwortlichkeit neu begründen könnten.
              Erst wenn wir feststellen oder von anderen darauf hingewiesen
              werden, dass ein konkretes Angebot, zu dem wir einen Link
              bereitgestellt haben, eine zivil- oder strafrechtliche
              Verantwortlichkeit auslöst, werden wir den Verweis auf dieses
              Angebot aufheben, soweit uns dies technisch möglich und zumutbar
              ist.
            </p>
            <br />
            <p>Urheberrecht</p>
            <p>
              Die durch den Betreiber dieser Seite erstellten Inhalte und Werke
              auf diesen Webseiten unterliegen dem deutschen Urheberrecht.
              Sämtliche Beiträge Dritter sind als solche gekennzeichnet. Die
              Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
              Verwertung außerhalb der Grenzen des Urheberrechts bedürfen der
              schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
              Kopien von diesen Seiten sind nur für den privaten Bereich
              gestattet, nicht jedoch für kommerzielle Zwecke. Für sämtliche
              Fotos, Texte, Lebensläufe der Künstler liegen die Urheberrechte
              bzw. die Nutzungsrechte bei dem Betreiber der Webseite.
            </p>
            <br />
            <p>Datenschutz</p>
            <p>
              Die Nutzung unserer Webseite ist in der Regel ohne Angabe
              personenbezogener Daten möglich. Soweit auf unseren Seiten
              personenbezogene Daten (beispielsweise Name, Anschrift oder
              E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich,
              stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
              ausdrückliche Zustimmung nicht an Dritte weitergegeben.
            </p>
            <p>
              In Verbindung mit Ihrem Zugriff werden in unserem Server für
              Zwecke der Datensicherheit vorübergehend Daten gespeichert, die
              möglicherweise eine Identifizierung zulassen (zum Beispiel
              IP-Adresse, Datum, Uhrzeit und Name der angeforderten Datei). Eine
              Auswertung, mit Ausnahme für statistische Zwecke in anonymisierter
              Form, erfolgt nicht.
            </p>
            <p>
              Sollten Sie bei uns Informationsmaterialien bestellen, benötigen
              wir von Ihnen Name, E-Mail-Adresse sowie Angaben zur Lieferung und
              Rechnungsstellung. So können wir Ihre Bestellung bearbeiten und
              ausführen. Diese Daten werden für die Dauer der Auftragsabwicklung
              in elektronischer Form gespeichert. Eine weitergehende Nutzung
              personenbezogener Daten oder eine Weitergabe an Dritte erfolgt
              ohne Ihre ausdrückliche Einwilligung nicht.
            </p>
            <p>
              Mit der Bestellung willigen Sie in die genannte Verarbeitung Ihrer
              personenbezogenen Daten ein, soweit dies für den Zweck des
              Vertrages erforderlich ist. Sie können diese Einwilligung
              schriftlich oder per E-Mail uns gegenüber jederzeit mit Wirkung
              für die Zukunft widerrufen.
            </p>
            <br />
            <p>Datenschutzerklärung für die Nutzung von Facebook-Plugins</p>
            <p>
              Auf unseren Seiten sind Plugins des sozialen Netzwerks Facebook,
              1601 South California Avenue, Palo Alto, CA 94304, USA integriert.
              Die Facebook-Plugins erkennen Sie an dem Facebook-Logo oder dem
              „Like-Button“ („Gefällt mir“) auf unserer Seite. Eine Übersicht
              über die Facebook-Plugins finden Sie hier:
              http://developers.facebook.com/docs/plugins/. Wenn Sie unsere
              Seiten besuchen, wird über das Plugin eine direkte Verbindung
              zwischen Ihrem Browser und dem Facebook-Server hergestellt.
              Facebook erhält dadurch die Information, dass Sie mit Ihrer
              IP-Adresse unsere Seite besucht haben.
            </p>
            <p>
              Wenn Sie den Facebook „Like-Button“ anklicken während Sie in Ihrem
              Facebook-Account eingeloggt sind, können Sie die Inhalte unserer
              Seiten auf Ihrem Facebook-Profil verlinken. Dadurch kann Facebook
              den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen
              darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom
              Inhalt der übermittelten Daten sowie deren Nutzung durch Facebook
              erhalten. Weitere Informationen hierzu finden Sie in der
              Datenschutzerklärung von facebook unter
              http://de-de.facebook.com/policy.php Wenn Sie nicht wünschen, dass
              Facebook den Besuch unserer Seiten Ihrem Facebook-Nutzerkonto
              zuordnen kann, loggen Sie sich bitte aus Ihrem
              Facebook-Benutzerkonto aus.
            </p>
            <br />
            <p>Datenschutzerklärung für die Nutzung von Google Analytics</p>
            <p>
              Diese Website benutzt Google Analytics, einen Webanalysedienst der
              Google Inc. („Google“). Google Analytics verwendet sog. „Cookies“,
              Textdateien, die auf Ihrem Computer gespeichert werden und die
              eine Analyse der Benutzung der Website durch Sie ermöglichen. Die
              durch den Cookie erzeugten Informationen über Ihre Benutzung
              dieser Website werden in der Regel an einen Server von Google in
              den USA übertragen und dort gespeichert.
            </p>
            <p>
              Im Falle der Aktivierung der IP-Anonymisierung auf dieser
              Webseite, wird Ihre IP-Adresse von Google jedoch innerhalb von
              Mitgliedstaaten der Europäischen Union oder in anderen
              Vertragsstaaten des Abkommens über den Europäischen
              Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die
              volle IP-Adresse an einen Server von Google in den USA übertragen
              und dort gekürzt. Im Auftrag des Betreibers dieser Website wird
              Google diese Informationen benutzen, um Ihre Nutzung der Website
              auszuwerten, um Reports über die Websiteaktivitäten
              zusammenzustellen und um weitere mit der Websitenutzung und der
              Internetnutzung verbundene Dienstleistungen gegenüber dem
              Websitebetreiber zu erbringenDie im Rahmen von Google Analytics
              von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen
              Daten von Google zusammengeführt.
            </p>
            <p>
              Sie können die Speicherung der Cookies durch eine entsprechende
              Einstellung Ihrer Browser-Software verhindern; wir weisen Sie
              jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht
              sämtliche Funktionen dieser Website vollumfänglich werden nutzen
              können. Sie können darüber hinaus die Erfassung der durch das
              Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten
              (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser
              Daten durch Google verhindern, indem sie das unter dem folgenden
              Link verfügbare Browser-Plugin herunterladen und installieren
              http://tools.google.com/dlpage/gaoptout?hl=de.
            </p>
            <br />
            <p>Datenschutzerklärung für die Nutzung von Instagram</p>
            <p>
              Mit Betätigen des entsprechenden Hyper-Links verlassen Sie die
              Seiten von Der Ku (UG).
              <br />
              Der Ku (UG) hat keinerlei Einfluss darauf, welche Benutzer-Daten
              die von Ihnen aufgerufene Webseite speichert, wie sie diese
              verarbeitet oder ob eine Weitergabe an Dritte stattfindet.
              <br />
              Informationen zu den von Instagram erfassten Daten und deren
              Verarbeitung finden Sie in der Datenschutzerklärung des sozialen
              Netzwerks. http://instagram.com/about/legal/privacy/
            </p>
          </div>
        </div>
      )}
      <div className="row justify-content-center">
        <div className="col-12 col-sm-8 col-md-4">
          <img
            src={EttiketRueck}
            height="80px"
            alt="Der Ku"
            style={{ width: '100%', height: 'auto' }}
          />
        </div>
      </div>
    </section>
  )
}

export default Impressum
