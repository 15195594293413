import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Link } from 'gatsby'
import leisteLogo from '../assets/images/Leiste_oben_neu.png'

// const MenuItem = props => (
//   <Link
//     to={props.path}
//     className={
//       'nav-link ' +
//       (props.location.pathname === props.path ? 'nav-item active' : 'nav-item')
//     }
//   >
//     <span>{props.label}</span>
//   </Link>
// )

const AnchorItem = ({ name, ...props }) => (
  <AnchorLink offset="100" href={`#${name.toLowerCase()}`} {...props}>
    {name}
  </AnchorLink>
)

export default function Navi(props) {
  return (
    <nav className="mt-4 p-0 navbar navbar-expand navbar-light">
      <div className="w-100 d-flex justify-content-between">
        <div className="navbar-nav container-fluid justify-content-center">
          <AnchorItem name={'Gin'} className="nav-item nav-link " />
          <AnchorItem
            name={'Destillerie'}
            className="nav-item nav-link d-none d-lg-flex"
          />
          <AnchorItem
            name={'Herstellung'}
            className="nav-item nav-link d-none d-lg-flex"
          />
          <AnchorItem
            name={'Xaver'}
            className="nav-item nav-link d-none d-sm-flex"
          />
          <AnchorItem name={'Bestellen'} className="nav-item nav-link " />
          <AnchorItem name={'Kontakt'} className="nav-item nav-link " />
          <AnchorItem
            name={'Impressum'}
            className="nav-item nav-link d-none d-md-flex"
          />
        </div>
      </div>
      <Link className="nav-logo-wrapper" to="/">
        <img src={leisteLogo} />
      </Link>
    </nav>
  )
}
